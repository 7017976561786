import React from 'react';
import { lang } from '../../../common/i18n';

interface AzureLoginButtonProps {
    uiMode?: string;
}

/**
 * SSO button for Azure login
 * @param {AzureLoginButtonProps} props
 * @returns {JSX.Element}
 */
export function AzureLoginButton(props: AzureLoginButtonProps): JSX.Element {
    const { uiMode } = props;
    return (
        <a className="sso-button sso-button-azure" href={'?page=sso&provider=azure' + (uiMode ? `&${uiMode}` : '')}>
            <img src="www/assets/images/microsoft_logo.svg" />
            {lang('login.sso.login.azure.button')}
        </a>
    );
}
