import * as React from 'react';
import ReactDOM from 'react-dom';
import ForgotPasswordView from './ForgotPasswordView';
import { ForgotPasswordViewProps } from './ForgotPasswordView';

export const ForgotPasswordViewRoot = (rootElem: Element, props: ForgotPasswordViewProps) => {
    document.addEventListener('DOMContentLoaded', () => {
        ReactDOM.render(<ForgotPasswordView {...props} />, rootElem);
    });
};
