import loadjs from 'loadjs';
import 'whatwg-fetch';

export const fetchUrls = async (location: string): Promise<[string]> => {
    const fetchConfigObject: RequestInit = {
        credentials: 'same-origin',
        headers: {
            'content-type': 'application/json',
        },
        method: 'GET',
    };
    const response = await fetch(`?page=ajax_nosession&module=ExternalApps&cmd=get_urls&location=${location}`, fetchConfigObject);
    if (response.status >= 400) {
        throw new Error('Error while fetching URLs.');
    }
    const urls = await response.json();
    return urls.data;
};

export const injectTags = async (location: string) => {
    if (!location) {
        throw new Error('Location missing');
    }
    const urls = await fetchUrls(location);
    loadjs(urls, 'my-bundle-id', {
        before: (path: string, scriptEl: HTMLScriptElement) => {
            document.body.appendChild(scriptEl);
            return false;
        },
    });
};

/**
 * Initialize external apps based on html page meta field with name "externalapps-location"
 * - if missing, does nothing
 * - if defined, then downloads and runs external js files defined to that location
 */
export const executeExternalAppsIfMeta = () => {
    const locationElement = document.querySelector<HTMLMetaElement>('head meta[name="externalapps-location"]');
    if (locationElement && locationElement.content) {
        injectTags(locationElement.content);
    }
};
