/**
 * Translates a localization key into translated string from current language
 *
 * @param {string} key localization key
 * @return {string} translated text
 */
export const lang = (key: string): string => {
    const langs = (window as any).langs || {};
    if (typeof langs[key] !== 'undefined') {
        return langs[key];
    }
    return '[' + key + ']';
};
