let handled = false;

/**
 * Redirects the user to the login page when they no longer have a session open.
 * @param {string} error optional error identifier received from the backend
 */
export const redirectUnauthorised = (error?: string) => {
    if (handled) {
        return;
    }
    const errorsMap: { [index: string]: string } = {
        wrong_session: 'login.session.wrong.error',
        expired_session: 'login.session_expired.error',
        force_logout: 'login.session.force_logout.error',
    };
    const location = error && errorsMap[error] ? `?error=${errorsMap[error]}` : '?loggedOut=1';
    window.location.assign(location);
    handled = true;
};
