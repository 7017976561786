import React from 'react';
import UserSelection from './UserSelection';
import LoginViewBase, { LoginViewBaseProps } from '../Login/LoginViewBase';
import { PromotionalIframeProps } from '../PromotionalIframe';

const SsoLoginView = (props: LoginViewBaseProps & PromotionalIframeProps) => {
    const { releaseNotesUrl, promotionalIframeUrl } = props;

    return (
        <LoginViewBase releaseNotesUrl={releaseNotesUrl} promotionalIframeUrl={promotionalIframeUrl}>
            <div>
                <img id="logoLogin" src="www/assets/images/brand/leaddesk_logo_blue.svg" />
                <UserSelection />
            </div>
        </LoginViewBase>
    );
};

export default SsoLoginView;
