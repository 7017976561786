const lang = (key: string, params?: Record<string, any>) => {

    if (!params) {
        return (window as any).lang(key);
    } else {
        let str: string = (window as any).lang(key);
        for (let keyName of Object.keys(params)) {
            str = str.replace(`{${keyName}}`, params[keyName]);
        }
        return str;
    }
};

export default lang;
