import React, { useState } from 'react';
import { lang } from '../../../common/i18n';
import { Recaptcha } from '../../../utils/google-recaptcha';
import { AzureLoginButton } from './AzureLoginButton';
import { GoogleLoginButton } from './GoogleLoginButton';

declare var window: any;
export interface LoginFormProps {
    errorMessage?: string;
    successMessage?: string;
    userName?: string;
    imei?: string;
    isRedisOnAndLoginsFailure: boolean;
    isCaptchaConfigured: boolean;
    captcha: Recaptcha;
    ssoConfig: {
        enabled: boolean;
        azure: {
            enabled: boolean;
        };
        google: {
            enabled: boolean;
            clientId: string;
        };
    };
    showSsoOnly: boolean;
    urlProfileUiParam?: string;
}

const LoginForm = (props: LoginFormProps) => {
    const [userName, setUserName] = useState(props.userName);
    const [showSsoOnly, setShowSsoOnly] = useState<boolean>(props.showSsoOnly);
    const { urlProfileUiParam, ssoConfig } = props;

    const handleChange = event => {
        setUserName(event.target.value);
    };

    const setRecaptchaValue = async () => {
        let recaptchaInput = document.getElementById('recaptcha') as HTMLInputElement;
        recaptchaInput.value = window.grecaptcha ? await window.grecaptcha.getResponse() : '';
        return true;
    };

    const renderSsoButtons = () => (
        <>
            {ssoConfig.azure.enabled && <AzureLoginButton uiMode={urlProfileUiParam} />}
            {ssoConfig.google.enabled && <GoogleLoginButton clientId={ssoConfig.google.clientId} uiMode={urlProfileUiParam} />}
        </>
    );

    if (showSsoOnly) {
        return (
            <div className="login-form-wrapper show-sso-only">
                <img id="logoLogin" src="www/assets/images/brand/leaddesk_logo_blue.svg" />
                <div className="ld_h3">{lang('login.sso.title')}</div>
                <div className="ld_body2">{lang('login.sso.sso_requirement.info')}</div>
                <div className="sso-login-wrapper">
                    {renderSsoButtons()}
                    <a className="ld_link" onClick={() => setShowSsoOnly(false)}>
                        {lang('login.sso.page.logout.button')}
                    </a>
                </div>
            </div>
        );
    }

    return (
        <div className="row login-form-wrapper text-center">
            <img id="logoLogin" src="www/assets/images/brand/leaddesk_logo_blue.svg" />
            <div>
                <h3 className="ld_h3">{lang('login.please_login.title')}</h3>
            </div>

            <div className="alert" id="error-message">
                <p>{props.errorMessage}</p>
            </div>

            <div className="success" id="success-message">
                {props.successMessage}
            </div>

            <form
                className="login-form"
                id="login-form"
                action={'./' + (urlProfileUiParam ? `?${urlProfileUiParam}` : '')}
                method="post"
                onSubmit={setRecaptchaValue}
            >
                <div className="input-container">
                    <label htmlFor="username">{lang('generic.username.label')}</label>
                    <input
                        id="username"
                        name="username"
                        type="text"
                        value={userName}
                        onChange={handleChange}
                        data-msg={lang('generic.check_username.error')}
                        required
                        autoFocus
                    />
                </div>
                <div className="input-container">
                    <label htmlFor="password">{lang('generic.password.label')}</label>
                    <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="off"
                        data-msg={lang('generic.check_password.error')}
                        required
                    />
                </div>
                <input type="hidden" name="imei" value={props.imei ? props.imei : ''} />
                <input type="hidden" name="submitlogin" value="Connect" />
                <input type="hidden" name="recaptcha" id="recaptcha" value="" />
                {props.isRedisOnAndLoginsFailure && props.isCaptchaConfigured && (
                    <div className="g-recaptcha" data-sitekey={props.captcha['key']} />
                )}

                <button className="button" id="login-button" type="submit">
                    {lang('generic.login.button')}
                </button>

                <div className="forgot-password-link-wrapper">
                    <a href={'?page=forgot_password' + (urlProfileUiParam ? `&${urlProfileUiParam}` : '')}>
                        {lang('login.forgot_your_password.label')}
                    </a>
                </div>

                {ssoConfig.enabled && (
                    <div className="sso-login-wrapper">
                        <hr />
                        {renderSsoButtons()}
                    </div>
                )}
            </form>
        </div>
    );
};

export default LoginForm;
