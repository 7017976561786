import React, { useEffect, useState } from 'react';
import ChangePasswordDialog from './ChangePasswordDialog';
import ChangePasswordForm from './ChangePasswordForm';
import axios from 'axios';

interface ChangePasswordProps {
    username?: string;
    title?: string;
    description?: string;
    forgotPassword?: boolean;
    validationRulesApi?: string;
    updatePasswordApi?: string;
    translate: (key: string) => string;
    onClose: (updated?: boolean) => any;
}

interface PasswordValidationRules {
    validationRules: { minLength: string; letters: string; digits: string };
    weakPasswords: { other?: [] };
    minLength: number;
}

const ChangePassword = (props: ChangePasswordProps) => {
    const {
        validationRulesApi = '?page=ajax_controller&module=Password&cmd=rules',
        updatePasswordApi = '?page=ajax_controller&module=Password&cmd=change',
        onClose,
        forgotPassword,
    } = props;
    const [passwordValidationRules, setPasswordValidationRules] = useState<PasswordValidationRules>();
    const [serverErrors, setServerErrors] = useState({ currentPassword: '', newPassword: '', generic: '' });
    const [open, setOpen] = useState(true);

    const fetchValidationRules = async () => {
        await axios(validationRulesApi)
            .then(result => setPasswordValidationRules(result.data))
            .catch(() => onClose());
    };

    const updatePassword = async (currentPassword, newPassword) => {
        await axios
            .post(updatePasswordApi, {
                oldPassword: currentPassword,
                newPassword,
            })
            .then(() => {
                setOpen(false);
                onClose(true);
            })
            .catch(err => {
                //(window as any).ShowError((err.response && err.response.data.description) || err.message);
                const errorKey = err.response.data.error;
                const errorDescription = err.response.data.description;
                const validation = err.response.data.validation;
                if (errorKey === 'validation_error') {
                    if (validation) {
                        setServerErrors({
                            ...serverErrors,
                            currentPassword: validation.oldPassword && validation.oldPassword[0],
                            newPassword: validation.newPassword && validation.newPassword[0],
                        });
                    }
                } else {
                    setServerErrors({ ...serverErrors, generic: errorDescription });
                }
            });
    };

    const handleClose = () => {
        setOpen(false);
        onClose();
    };

    useEffect(() => {
        fetchValidationRules();
    }, []);

    return (
        <>
            {passwordValidationRules &&
                (!forgotPassword ? (
                    <ChangePasswordDialog
                        {...props}
                        open={open}
                        regexes={passwordValidationRules.validationRules}
                        weakPasswords={passwordValidationRules.weakPasswords}
                        minLength={passwordValidationRules.minLength}
                        serverErrors={serverErrors}
                        updatePassword={updatePassword}
                        handleClose={handleClose}
                    />
                ) : (
                    <ChangePasswordForm
                        {...props}
                        regexes={passwordValidationRules.validationRules}
                        weakPasswords={passwordValidationRules.weakPasswords}
                        minLength={passwordValidationRules.minLength}
                        serverErrors={serverErrors}
                        updatePassword={updatePassword}
                    />
                ))}
        </>
    );
};

export default ChangePassword;
