import { lang } from '../common/i18n';
import { showChangePasswordDialog } from '../common/ChangePassword';
import axios from 'axios';

/**
 * Request user to change password if page contains expired-password meta field
 */
export const executeExpiredPasswordIfMeta = () => {
    if (document.querySelector<HTMLMetaElement>('head meta[name="expired-password"]') !== null) {
        showChangePasswordDialog({
            translate: lang,
            description: lang('login.expired_password.description.label'),
            validationRulesApi: '?page=ajax_nosession&module=Password&cmd=rules',
            updatePasswordApi: '?page=ajax_nosession&module=Password&cmd=change',
        }).then((wasUpdated: boolean) => {
            if (!wasUpdated) {
                // cancel password change session
                axios.post('?page=ajax_nosession&module=Password&cmd=cancel');
            }
        });
    }
};
