import React, { useEffect } from 'react';
import { showResetPasswordForm } from '../../resetPassword';

export interface ResetPasswordFormProps {
    loginUrl: string
}

const ResetPasswordForm = (props: ResetPasswordFormProps) => {
    const { loginUrl } = props;
    useEffect(() => {
        showResetPasswordForm('reset-password-form-container', loginUrl);
    });

    return (
        <div className="reset-form-wrapper">
            <div className="column" id="reset-password-form-container" />
        </div>
    );
};

export default ResetPasswordForm;
