import React, { useState, useEffect } from 'react';
import { Icon } from '@rmwc/icon';
import { NoSessionApi } from '../../../common/Api/NoSession';
import { lang } from '../../../common/i18n';

interface User {
    id: number;
    name: string;
    role: string;
    client: {
        id: string;
        name: string;
    }
}

const UserSelection = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [hasMultipleClients, setHasMultipleClients] = useState<boolean>(true);

    const fetchUsers = () =>
        NoSessionApi.get('Sso', 'users')
            .then(result => {
                const response = result.data.users;
                response.sort((a, b) =>
                    (a.client.name.localeCompare(b.client.name) || a.role.localeCompare(b.role) || a.name.localeCompare(b.name)));
                const clientIdArray = result.data.users.map(item => item.client.id);
                clientIdArray.sort();
                const hasMultipleClientIds = clientIdArray[0] !== clientIdArray[clientIdArray.length - 1];
                setHasMultipleClients(hasMultipleClientIds);
                return response;
            }).then(response => {
                setUsers(response);
            })
            .catch(() => {
                window.location.href = '?error=login.sso.session_expired.error';
            });

    useEffect(() => {
        fetchUsers();
    }, []);

    const selectUser = userId => {
        window.location.href = `?page=sso&phase=login&uid=${userId}`;
    };
    return (
        <div className="user-selection login-form-wrapper">
            <div className="title">{lang('login.sso.user_selection.title')}</div>
            <div className="users-list">
                {users.map(user => (
                    <div className="users-list__item" key={user.id} onClick={() => selectUser(user.id)}>
                        <div>
                            <div className="user-name">{user.name}</div>
                            <div className="user-role">{user.role}{hasMultipleClients && ` - ${user.client.name}`}</div>
                        </div>
                        <div>
                            <Icon icon="arrow_right" />
                        </div>
                    </div>
                ))}
            </div>
            <a className="logout" href="?page=sso&phase=cancel">
                {lang('login.sso.page.logout.button')}
            </a>
        </div>
    );
};

export default UserSelection;
