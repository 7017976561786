export const logout = (agentId?: number) => {
    rawCommand('use_default_voip_settings');
    if (agentId > 0) {
        // Settings vault isn't available in all cases so we have to clear the local storage key manually
        localStorage.removeItem(agentId + '.sessionStorage.voipSettings.applicationInitialized');
    }
};

function rawCommand(cmd: string, ...params: string[]): void {
    const cmdStr = `cmd://${[cmd, ...params].join('|')}|`;
    if (typeof window.LD !== 'undefined') {
        console.log('app', cmdStr);
        window.LD.executeCommand(cmdStr);
    }
}
