import { lang } from '../common/i18n';
import { showChangePasswordForm } from '../common/ChangePassword';
import axios from 'axios';

/**
 * Request user to change password if page contains expired-password meta field
 */
export const showResetPasswordForm = (container, loginURL) => {
    showChangePasswordForm(container, {
        translate: lang,
        forgotPassword: true,
        title: lang('login.reset_password.title'),
        validationRulesApi: '?page=ajax_nosession&module=Password&cmd=rules',
        updatePasswordApi: '?page=ajax_nosession&module=Password&cmd=change',
    }).then((wasUpdated: boolean) => {
        // cancel password change session
        axios.post('?page=ajax_nosession&module=Password&cmd=cancel');
        window.location.assign(loginURL);
    });
};
