import React from 'react';
import { ThemeProvider } from '@rmwc/theme';
import { COLORS } from '../Constants';

// @ts-ignore
const ThemeComponent = ({ children }) => (
    <ThemeProvider
        options={{
            primary: COLORS.primary,
            onPrimary: COLORS.white,
            secondary: COLORS.secondary,
            onSecondary: COLORS.white,
            error: COLORS.error,
        }}
    >
        {children}
    </ThemeProvider>
);

export default ThemeComponent;
