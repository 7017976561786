import React from 'react';

export interface PromotionalIframeProps {
    promotionalIframeUrl?: string;
}

const PromotionalIframe = (props: PromotionalIframeProps) => {
    const { promotionalIframeUrl } = props;

    if (promotionalIframeUrl) {
        return <iframe className="promotion-section" scrolling="no" src={promotionalIframeUrl}></iframe>;
    } else {
        return <></>;
    }
};

export default PromotionalIframe;
