/**
 * Listen cross domain events and process the supproted commands
 */
export const executeAttachMessageObserver = () => {
    // Attach message observer for cross-domain messages
    if (typeof window.addEventListener != 'undefined') {
        window.addEventListener('message', crossDomainEventMessage, false);
        window.CallNumberBy3rdParty = (phoneNumber, ref) => setCallToStore(phoneNumber, ref, 'app');
    }

    /**
     * Handle cross-domain messages
     * @param {event} event event message
     */
    function crossDomainEventMessage(event) {
        if (typeof event.data != 'string') {
            return;
        }

        var data = event.data.split('|');
        switch (data[0]) {
            /**
             * Call command from Talk browser plugin
             * format: call|phonenumber
             */
            case 'call': {
                if (data.length > 2) {
                    var phoneNumber = data[1].trim();
                    if (phoneNumber.length > 0) {
                        setCallToStore(phoneNumber, data[2].trim(), 'crossDomain');
                    }
                }
                break;
            }
        }
    }

    function setCallToStore(phoneNumber: string, ref: string, origin?: string) {
        var callRequest = {
            phoneNumber: phoneNumber,
            ref: ref,
            origin: origin,
        };
        sessionStorage.setItem('LeadDeskExtensionAPI_callTo', JSON.stringify(callRequest));
    }
};
