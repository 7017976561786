import Axios, { AxiosPromise } from 'axios';
import { fetchEventSource, FetchEventSourceInit, EventSourceMessage } from '@microsoft/fetch-event-source';
import { redirectUnauthorised } from './RedirectUnauthorised';

interface UrlParams {
    [key: string]: any;
}

type FetchEventSourceProps = FetchEventSourceInit & {
    urlParams?: UrlParams;
};

class SseTimeoutError extends Error {}
class SseUnauthorisedError extends Error {}
class SseInternalError extends Error {}

/**
 * Abstract API controller to call API commands from the backend
 */
export abstract class AbstractApi {
    /**
     * API handler page name. Needs to be defined in the concrete class.
     */
    protected static page: string;

    /**
     * Construct API command URL
     * @param  {string} module module name
     * @param  {string} cmd command name
     * @param  {UrlParams} urlParams request params
     * @return {string} URL
     */
    private static url(module: string, cmd: string, urlParams: UrlParams = {}): string {
        const params = new URLSearchParams();
        params.append('page', this.page);
        params.append('module', module);
        params.append('cmd', cmd);
        Object.keys(urlParams).forEach((key: string) => {
            params.append(key, urlParams[key]);
        });
        return '?' + params.toString();
    }

    /**
     * Make GET request to API and return results in a promise
     *
     * @param {string} module module name
     * @param {string} cmd command to execute
     * @param {UrlParams} params additional parameters
     * @return {AxiosPromise}
     */
    public static get<T = any>(module: string, cmd: string, params: UrlParams = {}): AxiosPromise<T> {
        return Axios.get(this.url(module, cmd), {
            params,
        });
    }

    /**
     * Perform a GET request to API that returns a binary file and return the results in a promise
     *
     * @param {string} module module name
     * @param {string} cmd command to execute
     * @param {string} mimeType the expected MIME type of the file
     * @param {UrlParams} params additional parameters
     * @return {AxiosPromise}
     */
    public static getFile(
        module: string,
        cmd: string,
        mimeType: string = 'application/octet-stream',
        params: UrlParams = {}
    ): AxiosPromise<any> {
        return Axios.get(this.url(module, cmd), {
            params,
            headers: { Accept: mimeType },
            responseType: 'arraybuffer',
        });
    }

    /**
     * Make POST request to API with JSON data and return results in a promise
     *
     * @param {string} module module name
     * @param {string} cmd command to execute
     * @param {any} json request json object
     * @return {AxiosPromise}
     */
    public static postJson<T = any>(module: string, cmd: string, json: any = {}, requestParams: UrlParams = {}): AxiosPromise<T> {
        return Axios.post(this.url(module, cmd), json, requestParams);
    }

    /**
     * Make POST request to API with params in URL and return results in a promise
     *
     * @param {string} module module name
     * @param {string} cmd command to execute
     * @param {UrlParams} urlParams request parameters
     * @return {AxiosPromise}
     */
    public static postUrl<T = any>(module: string, cmd: string, urlParams: UrlParams = {}): AxiosPromise<T> {
        return Axios.post(this.url(module, cmd, urlParams));
    }

    /**
     * Make POST request to API with the body as form data and return results in a promise
     *
     * @param {string} module module name
     * @param {string} cmd command to execute
     * @param {FormData} formData form input
     * @return {AxiosPromise}
     */
    public static postForm<T = any>(module: string, cmd: string, formData: FormData): AxiosPromise<T> {
        return Axios(this.url(module, cmd), {
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: formData,
        });
    }

    /**
     * Processes a stream of server-sent events.
     *
     * @param {string} module module name
     * @param {string} cmd command to execute
     * @param {FetchEventSourceProps} params event handlers and optional query parameters
     */
    public static eventSource(module: string, cmd: string, params: FetchEventSourceProps): void {
        fetchEventSource(this.url(module, cmd, params.urlParams), {
            openWhenHidden: true,
            onopen: async (response: Response) => {
                if (response.ok) {
                    params.onopen?.(response);
                    return;
                }
                if (response.status == 401) {
                    throw new SseUnauthorisedError(response.statusText);
                }
                if (response.status == 500) {
                    throw new SseInternalError(response.statusText);
                }
                return Promise.reject(response);
            },
            onmessage: (message: EventSourceMessage) => {
                if (!message.data) {
                    // Skip empty keep-alive messages.
                    return;
                }
                params.onmessage?.(message);
            },
            onclose: () => {
                // We arrive here if the backend closes the connection e.g. because a time limit has been reached.
                params.onclose?.();
                throw new SseTimeoutError();
            },
            onerror: (err: Error) => {
                if (err instanceof SseTimeoutError) {
                    // Returning nothing causes a retry.
                    return;
                }
                if (err instanceof SseUnauthorisedError) {
                    redirectUnauthorised('expired_session');
                }
                return params.onerror?.(err);
            },
        });
    }
}
