export const COLORS = {
    hover_gray: '#E7E6F6',
    white: '#FFFFFF',
    disabled: '#CCC',
    secondary: '#1E156A',
    primary: '#432EEA',
    error: '#F62437',
};

export enum UI_MODE {
    ADMIN = 'admin',
    OUTBOUND = 'outbound',
    OUTBOUND_V2 = 'outbound_v2',
    OMNI = 'omni',
    TALK_V1 = 'talk',
    TALK_V2 = 'talk_v2',
    MOBILE = 'mobile',
    EXTERNAL = 'external',
}

export enum CER_IDS {
    BUSY = 7,
}

export enum DATATABLE {
    CONTACT_COMMENT_HISTORY = 'contact_comment_history',
}
