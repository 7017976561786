import React from 'react';
import ReactDOM from 'react-dom';
import SsoLoginView from './SsoLoginView';
import { LoginViewBaseProps } from '../Login/LoginViewBase';

export const UserSelectionView = (rootElem: Element, props: LoginViewBaseProps) => {
    document.addEventListener('DOMContentLoaded', () => {
        ReactDOM.render(<SsoLoginView {...props} />, rootElem);
    });
};
