import { BrowserAuthError, BrowserCacheLocation, PublicClientApplication } from '@azure/msal-browser';
import { NoSessionApi, SsoStartResponse } from 'common/Api/NoSession';
import { FullScreenOverlay } from 'common/FullScreenOverlay';
import { lang } from 'common/i18n';
import $ from 'jquery';
import { Sso } from '..';

/**
 * Azure SSO handling logic
 */
export class Azure {
    /**
     * Initialize SSO handling for login screen
     */
    public static init() {
        FullScreenOverlay.install();
        const ssoButton = document.querySelector<HTMLAnchorElement>('a.sso-button-azure');
        if (ssoButton) {
            ssoButton.onclick = (event: MouseEvent) => {
                if (window.parent !== window) {
                    event.preventDefault();
                    new Azure().authWithPopup();
                    return false;
                }
            };
        }
    }

    /**
     * Authenticate with Azure account using a popup window
     */
    private async authWithPopup() {
        try {
            FullScreenOverlay.activate();
            $('#error-message').hide();
            const searchParams = new URLSearchParams(window.location.search);
            const requestParams = searchParams.has('profile_ui_mode')
                ? { profile_ui_mode: searchParams.get('profile_ui_mode') }
                : undefined;
            const response = await NoSessionApi.get<SsoStartResponse>('Sso', 'start', requestParams);
            const client = new PublicClientApplication({
                auth: {
                    clientId: response.data.client_id,
                    redirectUri: response.data.redirect_uri,
                },
                cache: {
                    cacheLocation: BrowserCacheLocation.MemoryStorage,
                },
            });

            const login = await client.loginPopup({ scopes: ['email'], nonce: response.data.nonce });
            Sso.submitIdToken('?page=sso&provider=azure&type=popup', login.idToken);
        } catch (err) {
            FullScreenOverlay.deactivate();
            if (err instanceof BrowserAuthError) {
                switch (err.errorCode) {
                    case 'user_cancelled':
                        // User cancelled the SSO process
                        $('#error-message')
                            .text(lang('login.sso.user_cancelled.error'))
                            .show();
                        return;
                    case 'popup_window_error':
                        // Most likely opening a popup was blocked
                        $('#error-message')
                            .text(lang('login.sso.open_popup_window.error'))
                            .show();
                        return;
                }
            }
            $('#error-message')
                .text(lang('login.sso.azure.error'))
                .show();
            console.error('azure sso login failed', err);
        }
    }
}
