import React from 'react';
import { Button } from '@rmwc/button';
import { lang } from '../../../common/i18n';
import PromotionalIframe, { PromotionalIframeProps } from '../PromotionalIframe';

export interface LoginViewBaseProps {
    releaseNotesUrl?: string;
    children?: React.ReactNode;
}

const LoginViewBase = (props: LoginViewBaseProps & PromotionalIframeProps) => {
    const { releaseNotesUrl, promotionalIframeUrl } = props;
    return (
        <div className="loginBase">
            <div className="loginView">
                <div className="top-bar">
                    <div className="PCS-news-bar" />
                    <div className="noAccount-row">
                        <p>{lang('login.help_row.no_account.button')}</p>
                        <Button
                            tag="a"
                            href="https://leaddesk.com/free-trial/"
                            target="_blank"
                            rel="noreferrer"
                            id="free-trial-button"
                            label={lang('login.top_row.free_trial.button')}
                            outlined
                        />
                    </div>
                </div>
                {props.children}
                <div className="bottom-div">
                    <div className="information-row">
                        {releaseNotesUrl && (
                            <a href={releaseNotesUrl} target="_blank" rel="noreferrer">
                                {lang('login.information_row.release_notes.button')}
                            </a>
                        )}
                        <div className="help">
                            <i className="material-icons">support</i>
                            <a href="http://support.leaddesk.com" target="_blank" rel="noreferrer">
                                {lang('login.help_card.title')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <PromotionalIframe promotionalIframeUrl={promotionalIframeUrl} />
        </div>
    );
};

export default LoginViewBase;
