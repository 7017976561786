import { executeExternalAppsIfMeta } from './externalApps';
import { showResetPasswordForm } from './resetPassword';
import { logout } from './logout';
import './styles/outside.scss';
import { UserSelectionView } from './components/SsoLogin';
import { LoginViewRoot } from './components/Login';
import { ForgotPasswordViewRoot } from './components/ForgotPassword';
import { executeExpiredPasswordIfMeta } from './expiredPassword';
import { executeAttachMessageObserver } from './eventListener';
import { ResetPasswordViewRoot } from './components/ResetPassword';

import { Globals } from './globals';
import { Azure } from './Sso/Azure';
/**
 * Outside bundle is intended to contain all code needed to run and render pages outside of user session.
 * Mainly login page and all similar pages where user it not yet fully authenticated.
 */

/**
 * Bundle's side effects
 */
executeExternalAppsIfMeta();
executeExpiredPasswordIfMeta();
executeAttachMessageObserver();

/**
 * Bundle's published code/functions
 *
 * Let's keep this to a minimum and prefer to have all code inside the bundle
 */
(window as any).Outside = {
    showResetPasswordForm,
    logout,
    Components: {
        UserSelectionView,
        LoginViewRoot,
        ForgotPasswordViewRoot,
        ResetPasswordViewRoot
    },
    Sso: {
        Azure,
    },
};

/**
 * Add globals
 */
declare var global: any;
Object.assign(global, Globals);
