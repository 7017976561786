import * as React from 'react';
import ReactDOM from 'react-dom';
import ResetPasswordView from './ResetPasswordView';
import { ResetPasswordViewProps } from './ResetPasswordView';

export const ResetPasswordViewRoot = (rootElem: Element, props: ResetPasswordViewProps) => {
    document.addEventListener('DOMContentLoaded', () => {
        ReactDOM.render(<ResetPasswordView {...props} />, rootElem);
    });
};
