import React from 'react';
import LoginForm from '../LoginForm';
import { LoginFormProps } from '../LoginForm';
import LoginViewBase, { LoginViewBaseProps } from './LoginViewBase';
import { PromotionalIframeProps } from '../PromotionalIframe';

export type LoginViewProps = LoginViewBaseProps & LoginFormProps & PromotionalIframeProps;

const LoginView = (props: LoginViewProps) => {
    const { releaseNotesUrl, promotionalIframeUrl } = props;

    return (
        <LoginViewBase releaseNotesUrl={releaseNotesUrl} promotionalIframeUrl={promotionalIframeUrl}>
            <LoginForm {...props} />
        </LoginViewBase>
    );
};

export default LoginView;
