import React from 'react';

let activate = () => {};
let deactivate = () => {};

/**
 * Full screen overlay to prevent all user actions
 *
 * @return {JSX.Element}
 */
const OverlayComponent = () => {
    const [show, setShow] = React.useState(false);
    activate = () => {
        setShow(true);
    };
    deactivate = () => {
        setShow(false);
    };
    return <div className={'fullscreen-overlay' + (show ? ' fullscreen-overlay--active' : '')} />;
};

export { activate, deactivate, OverlayComponent };
