/**
 * Generic SSO functionality
 */
export class Sso {
    /**
     * Submit idToken using POST login request
     *
     * @param {string} url
     * @param {string} idToken
     */
    public static submitIdToken(url: string, idToken: string) {
        const form = document.createElement('form');
        form.style.visibility = 'hidden';
        form.method = 'POST';
        form.action = url;
        const token = document.createElement('input');
        token.name = 'id_token';
        token.value = idToken;
        form.appendChild(token);
        document.body.appendChild(form);
        form.submit();
    }
}
