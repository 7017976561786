import React from 'react';
import ForgotPasswordForm from '../ForgotPasswordForm';
import { Recaptcha } from '../../../utils/google-recaptcha';
import LoginViewBase from '../Login/LoginViewBase';
import { PromotionalIframeProps } from '../PromotionalIframe';

export interface ForgotPasswordViewProps {
    errorMessage: string;
    captcha: Recaptcha;
    langId: number;
    loginPageLocation: string;
    releaseNotesUrl: string;
}

const ForgotPasswordView = (props: ForgotPasswordViewProps & PromotionalIframeProps) => {
    const { releaseNotesUrl, promotionalIframeUrl } = props;
    return (
        <LoginViewBase releaseNotesUrl={releaseNotesUrl} promotionalIframeUrl={promotionalIframeUrl}>
            <ForgotPasswordForm {...props} />
        </LoginViewBase>
    );
};

export default ForgotPasswordView;
