import React from 'react';
import { Dialog } from '@material-ui/core';
import { ChangePasswordForm } from './ChangePasswordForm';

interface ChangePasswordDialogProps {
    username?: string;
    title?: string;
    description?: string;
    regexes: { minLength: string; letters: string; digits: string };
    weakPasswords: { other?: [] };
    minLength: number;
    forgotPassword?: boolean;
    serverErrors: { currentPassword: string; newPassword: string; generic: string };
    open: boolean;
    translate: (key: string) => string;
    handleClose: () => any;
    updatePassword: (currentPassword: string, newPassword: string) => any;
}

const ChangePasswordDialog = (props: ChangePasswordDialogProps) => {
    return (
        <>
            <Dialog open={props.open} disableEnforceFocus disableBackdropClick className="change-password-dialog">
                <ChangePasswordForm {...props} />
            </Dialog>
        </>
    );
};

export default ChangePasswordDialog;
