import React from 'react';
import ReactDOM from 'react-dom';
import { activate, deactivate, OverlayComponent } from './OverlayComponent';

/**
 * Full screen overlay manager
 */
export class FullScreenOverlay {
    /**
     * Installs the overlay component in DOM. Must be done before calling other methods
     */
    static install() {
        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', () => this.appendOverlayElement());
        } else {
            this.appendOverlayElement();
        }
    }

    /**
     * Append the overlay component into DOM
     */
    private static appendOverlayElement() {
        const root = document.createElement('div');
        root.id = 'fullscreen-overlay-root';
        document.body.appendChild(root);
        ReactDOM.render(<OverlayComponent />, root);
    }

    /**
     * Activate overlay effect preventing any user actions on UI
     */
    static activate() {
        activate();
    }

    /**
     * Deactivate overlay effect
     */
    static deactivate() {
        deactivate();
    }
}
